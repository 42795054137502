<template>
    <v-card
            class="mx-auto"
            outlined
    >
        <v-toolbar
                color="primary"
        >
            <v-toolbar-title class="white--text">Testcase Search/Details</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line>
            <v-list-item>
                <v-list-item-content>
                    <v-row no-gutters>
                        <v-col cols="2">
                            <primaryTextfield
                                    :fieldAttrInput="{ class: 'required ml-3' }"
                                    style="max-width: 300px"
                                    label="Testcase Number"
                                    v-model="testcaseNumber"
                            >
                            </primaryTextfield>
                        </v-col>
                        <v-col cols="2">
                            <v-btn
                                    outlined
                                    text
                                    :class="checkClass"
                                    @click="getTestcaseDetails"
                                    :disabled="disableButt"
                                    style="height:40px;"
                            >
                               <v-icon left>
                                    mdi-magnify
                                </v-icon>
                                Find
                            </v-btn>
                        </v-col>
                    </v-row>
                    <template v-if="localData">
                        <div class="dataList ml-3">
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Testcase Info</v-col>
                                <v-col><a :href="testcaseURL" target="_blank">TestInfo URL</a></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Testcase Name</v-col>
                                <v-col>{{localData.testcaseName}}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Testcase Created By</v-col>
                                <v-col>{{localData.c_firstname}} {{localData.c_lastname}} ( {{localData.c_email}} )( {{localData.userHash}} )
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Executed by</v-col>
                                <v-col>{{localData.excutorName}} ( {{localData.excutorEmail}} ) ( {{localData.executor}}  )
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Date</v-col>
                                <v-col><strong>Issued:</strong> {{localData.testcaseDate.iTime}} | <strong>Started:</strong> {{localData.testcaseDate.sTime}} | <strong>Finished:</strong> {{localData.testcaseDate.eTime}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Status</v-col>
                                <v-col><label :style="'color:rgb('+localData.testcaseStatusColor+')'">{{localData.testcaseStatusVal}}</label>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-3 mt-3"></v-divider>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Project Name</v-col>
                                <v-col>{{localData.projectName}} ( {{localData.projectHash}} )</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Functionality and Area of Testing</v-col>
                                <v-col>{{localData.projectAllowedTypes}}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" class="subtitle-2">Organisation Name</v-col>
                                <v-col>{{localData.orgName}} ( {{localData.orgHash}} )</v-col>
                            </v-row>
                        </div>
                    </template>
                    <template v-else>
                        <p class="ml-4 red--text">{{noData}}</p>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>
<script>
    import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
    import requests from '@/js/requests';

    export default {
    props: ["row","currentTable","result","isValid"],
    data:function(){
      return {
          testcaseNumber:"",
          localData:"",
          noData:"",
          disableButt:true,
          testcaseURL:"",
      };
    },
    components: {
        primaryTextfield
    },
    watch: {
          "testcaseNumber": function (value) {
            this.disableButt=true;
            if(value.length>0){
                this.disableButt=false;
            }
          },
    },
    computed:{
     checkClass(){
         let classList="ml-3 primary  white--text";
         if(this.disableButt){
             classList = "ml-3 v-btn--disabled v-btn--has-bg  white--text";
         }
        return classList;
     }
    },
    methods: {
      getTestcaseDetails(){
        this.localData="";
        this.testcaseURL="";
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php?f=administration&f2=testcaseDetails',
          dataType: 'json',
          data: {
            tNumber: this.testcaseNumber,
            requestType: 'ajax',
          },
        })
            .then(response => {
             // console.log(Object.keys(response.data.result.testcaseDetailsValue).length);
            if(Object.keys(response.data.result.testcaseDetailsValue).length!= 0){
                caller.localData=response.data.result.testcaseDetailsValue ;
                caller.testcaseURL=response.data.result.testcaseURL;
            }else{
                caller.noData="No Testcase Found!";
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  }
</script>
<style scoped>
    .dataList > .row:hover {
        background-color: #eeeeee;
    }
</style>